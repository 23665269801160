let hn = process.env.REACT_APP_GLOBALTenant_SERVICE_URL
// let hn
// if (gTSUrl) {
// 	if (gTSUrl.includes('://')) hn = gTSUrl.split('://')[1].split('/')[0]
// 	else hn = gTSUrl.split('/')[0]
// 	if (hn.split('.').length == 1) hn = hn + '.' + window.location.hostname.split('/')[0].split('.').slice(1).join('.')
// 	if (hn.includes(':')) hn = gTSUrl
// }
let envs = ['dev', 'test', 'staging', 'stage', 'alpha', 'beta','appconfig','appconfig-qa','91social-dev','91social-qa','botstudio-dev','botstudio-qa']
let globalTSUrl = addEnv1(hn, 'apps.actionable-science.com')
let cdn = process.env.REACT_APP_BOT_CDN || process.env.REACT_APP_CDN_URI
let cdnU = addEnv1(cdn)
function addEnv1(str, rootDomain) {
	if (!str) return
	if (str && !str.includes('://')) str = 'https://' + str
	let str1 = 'https://' + str.split('://')[1].split('/')[0]
	let path = str.split('://')[1].split('/')[1] ? '/' + str.split('://')[1].split('/').slice(1).join('/') : ''
	let retStr = str
	if (str1 && !str1.includes('localhost')) {
		envs.some(e1 => {
			let e = `.${e1}.`
			if (window.location.hostname.includes(e) && !str1.includes(e)) {
				if (!str1.includes('.')) {
					// append entire root here.
					retStr = `${str1}-${e.slice(1)}${rootDomain}${path || ''}`
				} else {
					// insert e after prefix domain...
					let x = str1.split('.')
					x.splice(1, 0, e.slice(1, -1))
					retStr = x.join('.') + (path || '')
				}
				if (e.includes('staging')) {
					retStr = retStr.replace('staging', 'uat')
				}
				if (e.includes('stage')) {
					retStr = retStr.replace('stage', 'preprod')
				}
				return retStr
			}
		})
		return retStr
	} else return str
}
export const cdnUri = null
//export const cdnUri = process.env.REACT_APP_CDN_URI || process.env.REACT_APP_BOT_CDN
export const globalTenantServiceUrl =
	(globalTSUrl || process.env.REACT_APP_API_HOST) + (hn && !hn.includes('/') ? process.env.REACT_APP_API_BASE_URL || '/api/v1' : '')
function addEnv(str) {
	let retStr = str
	if (str && !str.includes('localhost')) {
		envs.forEach(e1 => {
			let e = `.${e1}.`
			if (window.location.hostname.includes(e) && !str.includes(e)) {
				let rootDomain = window.location.hostname.split(e)[1]
				let newStr
				if (str.includes('://')) newStr = str.split('://')[1].split('/')[0]
				else newStr = str.split('/')[0]
				retStr = str.replace(newStr, newStr + e + rootDomain)
				return
			}
		})
		return retStr
	}
	return retStr
}
console.log(globalTenantServiceUrl)
export const localTest = process.env.REACT_APP_LOCAL === undefined ? false : process.env.REACT_APP_LOCAL
// export const defaultTenantId = process.env.REACT_APP_DEFAULTTENANTID;
export const TARGET_TENANT_DOMAIN = process.env.REACT_APP_TARGET_TENANT_DOMAIN || 'asc-ticket.virtualpeople.ai'

export const i18nSaving = process.env.REACT_APP_I18N_SAVING_MISSIG || false

export const realmName = process.env.REACT_APP_REALNAME
export const singleTenant = process.env.REACT_APP_SINGLETENANT
export const registerSubDomain = process.env.REACT_APP_REGISTER_SUBDOMAIN || process.env.REACT_APP_DEFAULT_SUBDOMAIN || 'www'
const env = envs.some(e => window.location.hostname.includes(e)) && window.location.hostname.split('.')[1]
export const appRootPath = process.env.REACT_APP_APPROOTPATH || '/'
export const rootDomains = env && env != 'false' ? `${env}.virtualpeople.ai,${env}.rezolve.ai` : `virtualpeople.ai,rezolve.ai`
export const localeUrl = process.env.REACT_APP_LOCALEURL || ''
export const appName = 'mainapp'

export const session = new Map()
export const apiBaseUrlPath = ''
export const isProd = process.env.REACT_APP_PROD || false
