import { ContentBlock, ContentState, genKey } from 'draft-js'
import marked from 'marked'
import React from 'react'
import turndown from 'turndown'
//import { cdnUri } from '../../../../../config'

const cdnUri = localStorage.getItem('cdnUri')
const iconList = {
	txt: `${cdnUri}/cdn/txt.png`,
    pdf: `${cdnUri}/cdn/pdf.png`,
    xls: `${cdnUri}/cdn/xlsx.png`,
    xlsx: `${cdnUri}/cdn/xlsx.png`,
    doc: `${cdnUri}/cdn/word.png`,
    docx: `${cdnUri}/cdn/word.png`,
    ppt: `${cdnUri}/cdn/ppt.png`,
    csv: `${cdnUri}/cdn/cvs.png`,
    tsv: `${cdnUri}/cdn/txt.png`,
    defaultVideo:`${cdnUri}/cdn/thumbnail.png`,
    defaultAttach: `${cdnUri}/cdn/txt.png`
}

let turndownService = new turndown()
turndownService.addRule('strikethrough', {
	filter: ['del', 's', 'strike'],
	replacement: function (content) {
		return '~~' + content + '~~'
	},
})
turndownService.addRule('h5', {
	filter: ['h3', 'h3', 'smallheading'],
	replacement: function (content) {
		return '##### ' + content
	},
})
//turndown rule for video tag
turndownService.addRule('video', {
	filter: ['video'],
	replacement: function (content) {
		return `[video]${content}[/video]`
	},
})

const VideoBlock = props => {
	return <video controls poster={iconList.defaultVideo} preload="none" src={props.link} />
}

const ImageBlock = props => {
	// @ts-ignore
	return <img src={props.link} 
		alt={props.alt} 
		// width={props.width}
		// height={props.height}
		style={{ width: props.width, height: props.height}}
	/>
}
const AttachmentBlock = props => {
	return (
		<a href={props.link}>
			<img style={{ maxWidth: '80px' }} src={props.icon || iconList.defaultAttach} />
		</a>
	)
}

// media component for blockrender func of textEditor for rendering video and image in editor
const Media = props => {
	const entity = props.contentState.getEntity(props.block.getEntityAt(0))
	const { icon, src, link, height, width, alt } = entity.getData()
	const type = entity.getType()
	let media
	if (type === 'VIDEO') {
		media = <VideoBlock link={src} />
	} else if (type === 'IMAGE') {
		media = <ImageBlock link={src} alt={alt} width={width} height={height} />
	} else if (type === 'ATTACHMENT') {
		media = <AttachmentBlock icon={icon} link={link} />
	}
	return media
}
// media component for blockrender func of textEditor for rendering video and image in editor

// custom entity added for video in textEditor using in draftToHTML before save and update
const customEntityTransform = (entity, text) => {
	if (entity && entity.type === 'VIDEO') {
		return `<video preload="none" poster="${iconList.defaultVideo}" src="${entity.data.link || entity.data.src}">${entity.data.link || entity.data.src}</video>`
	} else if (entity && entity.type === 'ATTACHMENT') {
		return `<a href=${entity.data.link || entity.data.src}><img src=${entity.data.icon || iconList.defaultAttach} /></a>`
	} else return
}
// custom entity added for video in textEditor using in draftToHTML before save and update

//convert video markdown to video tag
const convertCustomTags = (value, editor) => {
	if (value && typeof value == 'string') {
		let convertToTags = value
		convertToTags = value.replace(/\[video\](.*?)\[\/video\]/g, `<video poster="${iconList.defaultVideo}" preload="none" src="$1"></video>`)
		if (editor) {
			convertToTags = convertToTags.replace(/\[(?:!\[(.*?)\]\((.*?)\))\]\((.*?)\)/g, (matchedString, title, icon, link) => {
				return `<attachment icon="${icon}" link="${link}"></attachment>`
			})
		}
		return convertToTags
	} else return null
}
//convert video markdown to video tag

const convertQuillTags = (value, editor) => {
	if (value && typeof value == 'string') {
		let convertToTags = value
		convertToTags = value.replace(/\[video\](.*?)\[\/video\]/g, `<video poster="${iconList.defaultVideo}" preload="none" src="$1"></video>`)
		if (editor) {
			convertToTags = convertToTags.replace(/\[(?:!\[(.*?)\]\((.*?)\))\]\((.*?)\)/g, (matchedString, title, icon, link) => {
				return `<a href="${link}"><img src='${icon}'/></a>`
			})
		}
		return convertToTags
	} else return null
}

//Block renderer Function for Editor (video and images) atomic block check
const mediaBlockRenderer = block => {
	if (block.getType() === 'atomic') {
		return {
			component: Media,
			editable: false,
		}
	}
	return null
}
//Block renderer Function for Editor (video and images) atomic block check

const renderAttachment = (nodeName, node) => {
	if (nodeName === 'attachment' && node) {
		let icon = node.getAttribute('icon')
		let link = node.getAttribute('link')
		return {
			type: 'ATTACHMENT',
			mutability: 'MUTABLE',
			data: { icon, link },
		}
	}
}

const serializeFileName = val => {
	if (val) return val.replace(/[/\\?%*.@$#&~^_()[\]\s+:|"<>]/g, '-')
}

const getIcon = type => {
	if (type && typeof type === 'string' && iconList) {
		return iconList[type] ? iconList[type] : iconList['default']
	} else return iconList['default']
}
const renderContent = () => {
	let render = new marked.Renderer()
	render.list = function (body, ordered, start) {
		let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
		if (ordered) {
			return `<ol>${newtext}</ol>`
		} else {
			return `<ul>${newtext}</ul>`
		}
	}

	render.blockquote = function (quote) {
		let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
		return `<blockquote>${newtext}</blockquote>`
	}
	render.code = function (string) {
		return string
	}
	render.image = function (href, title, text, alt, width, height) {
		var newImage = `<img src="${href}" alt="Image" title="Image"><p></p>`
		return newImage
	}
	return render
}
const atomicBlocks = async content => {
	const lastType = content.getLastBlock().getType()

	if (lastType === 'atomic') {
		const blankLine = new ContentBlock({
			key: genKey(),
			text: '',
			type: 'unstyled',
		})

		const newBlockArray = content.getBlockMap().set(blankLine.key, blankLine).toArray()

		return ContentState.createFromBlockArray(newBlockArray)
	}

	return content
}

const fixSublists = node => {
	var ulElements = Array.from(node.getElementsByTagName('ul'))
	var olElements = Array.from(node.getElementsByTagName('ol'))
	var listElements = ulElements.concat(olElements)
	listElements.forEach(function (listNode) {
		if (listNode.previousElementSibling.tagName.toUpperCase() === 'LI') {
			listNode.previousElementSibling.appendChild(listNode)
		}
	})
	return node
}

const convertToOutput = async ({ value, outputFormat, tenantId, purpose, colorPicker, list }) => {
	let output = value || {}
	if (outputFormat == 'markdown') {
		if (colorPicker) {
			turndownService.addRule('p', {
				filter: ['p'],
				replacement: (content, node) => node.outerHTML + '\n\n',
			})
		}
		if (list) {
			turndownService.addRule('list', {
				filter: ['ul', 'li', 'ol'],
				replacement: (content, node) => {
					let root = fixSublists(node)
					return root.outerHTML
				},
			})
		}
		output = await turndownService.turndown(value)
		output = convertMediaPathToPvtCdn({ value: output, tenantId, purpose: purpose })
	}
	return output
}
export const convertMediaPathToPvtCdn = ({ value, tenantId, purpose }) => {
	let responseValue = value
	if (value && tenantId) {
		let regex = new RegExp(`${cdnUri}/${tenantId}-${purpose}/`, 'g')

		responseValue = value.replace(regex, '')
		responseValue = responseValue.replace(/\?sv=([^)|\[]+)/g, '')

		//regex for video to map video src within SASUrl[]
		responseValue = responseValue.replace(/\[video\](.*?)\[\/video\]/g, (matched, url) => {
			return `[video]SASUrl[${url}][/video]`
		})
		//regex for video to map video src within SASUrl[]

		//regex for images to map image src within SASUrl[]
		responseValue = responseValue.replace(/!\[[^\]]*\]\((.*?)\s*(?:"(?:.*[^"])")?\s*\)/g, (matchedString, SASString) => {
			if (isValidUrl(SASString)) return `![image](${SASString})`
			else {
				if (SASString.indexOf('SASUrl') == -1) return `![image](SASUrl[${SASString}])`
				else {
					let newSASString = SASString.replace(/SASUrl\[['"]*([^\]^'^"]*)['"]*\]/g, '$1')
					if (newSASString) {
						if (isValidUrl(newSASString)) return `![image](${newSASString})`
						else return `![image](SASUrl[${SASString}])`
					} else return SASString
				}
			}
		})

		//regex for attachment to map attachment src within SASUrl[]
		responseValue = responseValue.replace(/\[(?:!\[(.*?)\]\((.*?)\))\]\((.*?)\)/g, (matchedString, title, icon, link) => {
			return `[![image](${icon})](SASUrl[${link}])`
		})
		//regex for attachment to map attachment src within SASUrl[]
	}
	return responseValue
}
export const isValidUrl = url => {
	try {
		new URL(url)
	} catch (_) {
		return false
	}
	return true
}
export const _processOtherMedia = text => {
	if (text) {
		text = text.replace(/SASUrl[['"](.*?)['"]*]/g, (matchedString, SASString) => {
			return SASString
		})
		return text
	}
}

const getMediaWithToken = ({ text, tenantId, key, SASToken }) => {
	if (!text || !tenantId || !cdnUri) return text
	if (typeof text != 'string') return text
	let blobNames = text && [...text.matchAll(/SASUrl\[['"]*([^\]^'^"]*)['"]*\]/g)].map(a => a[1])
	let SASUrl = {}
	let containerKey = key ? tenantId + '-' + key : tenantId
	blobNames.forEach(b => {
		if (b.indexOf('SASUrl') == -1) {
			SASUrl[b] = `${cdnUri}/${containerKey}/${b}` //?${SASToken}`
			text = text.replace(new RegExp(`SASUrl[['"]*${b}['"]*]`), SASUrl[b])
		}
	})
	text = _processOtherMedia(text)
	text = text.replace(/&#x2F;/g, '/')
	return text
}

export {
	Media,
	customEntityTransform,
	convertCustomTags,
	mediaBlockRenderer,
	renderAttachment,
	serializeFileName,
	getIcon,
	renderContent,
	atomicBlocks,
	convertToOutput,
	getMediaWithToken,
	convertQuillTags
}
