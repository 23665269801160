import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  Input,
  Tooltip,
  Box,
  Button,
  Textarea,
  Text,
  Stack, Tab, TabPanels, TabPanel, Tabs, TabList,
  DrawerFooter
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { BiHelpCircle } from 'react-icons/bi';
import { identityServices } from '..';
import { QuillEditor } from './QuillEditor/QuillEditor';
import * as editorFunc from './Editor/plugin/customBlock'
import { IoArrowBackCircleOutline } from "react-icons/io5";
import styled from 'styled-components';
import marked from 'marked';

const CustomTabList = styled(TabList)`
    background-color: #f2f3f4;
    border-radius: 6px;
    padding: 8px;
    column-gap: 5px;
    justify-content: space-around !important;
    height: 45px;
    > button {
      border: 0 !important;
      &:focus {
        box-shadow: none;
      }
      &[tabindex='0'] {
        background-color: #fdfdfd;
        color: black;
        border-radius: 6px;
      }
    }
`;
const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)

const drawerHeaderStyles = {
  top: '20px',
  zIndex: 111,
  backgroundColor: '#fff',
  width: 'calc(100% - 50px)'
};
const tabListStyles = {
  top: '83px',
  left: '20px',
  right: '20px',
  zIndex: 111,
  display: 'flex',

};
const scrollbar = {
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '5px'
  },
}


export default function DrawerHelper(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [comments, setComments] = useState('');
  const [textEditor, setTextEditor] = useState(null);
  const btnRef = useRef();


  useEffect(() => {
    if (props?.tenant?.identity?.roles?.includes('asc_admin' || 'ASC Admin')) {
      setData(prev => ({
        ...prev,
        EditIcon: true,
      }));
    } else {
      setData(prev => ({
        ...prev,
        EditIcon: false,
      }));
    }

    setTextEditor({
      attachment: {
        allowed: ['txt', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'csv', 'tsv']
      },
      video: {
        allowed: ['mp4'],
        maxUploadSize: 10
      }
    })
  }, [])

  const tabListStyles = {
    top: '83px',
    left: '20px',
    right: '20px',
    zIndex: 111,
    display: 'flex',

  };

  const [data, setData] = useState({
    quickHelpContent: '',
    support: '',
    iframe: '',
    EditIcon: false,
  });

  const toast = useToast()

  const toolbar = [
    ['undo', 'redo'],
    ["bold", "italic"],
    [{ font: ['sans-serif', 'serif', 'monospace'] }],
    [{ header: [1, 2, 3, false] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video", "attachment"],
  ]

  const getMeta = (file) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      var url = URL.createObjectURL(file)
      img.onload = () => resolve({ height: img.height.toString(), width: img.width.toString() })
      img.onerror = reject
      img.src = url
    })
  }

  const UploadImages = (file) => {
    const { t, tenant } = props.tenant
    if (file) {
      //this.setState({ errorMsg: false })
      // @ts-ignore
      let fileSize = parseInt(file['size'] / 1024 / 1024)
      let fileExtension = /(.*?)\.(jpg|bmp|jpeg|JPG|JPEG|PNG|png|gif|GIF)$/
      if (!file['name']?.match(fileExtension)) {
        //this.setState({ errorMsg: 'Upload only jpg, gif or png format Image' })
        return new Promise((resolve, reject) => {
          resolve({ data: { link: null } })
        })
      }
      if (fileSize > 9) {
        this.setState({ errorMsg: t('Uploading Image Size must be less than 10MB') })
        return new Promise((resolve, reject) => {
          resolve({ data: { link: null } })
        })
      } else {
        function getFileNameAndExtension(fname) {
          const filename = fname
            .split('.')
            .shift()
            .replace(/[^A-Za-z0-9]/g, '')
          const extension = fname.split('.').pop()
          return { filename, extension, fullname: `${filename}.${extension}` }
        }
        const myRenamedFile = new File([file], getFileNameAndExtension(file.name).fullname)
        //this.setState({ errorMsg: false })
        let fileInfo = { type: 'image', access: 'public' }
        const formData = new FormData()
        let metadata = getMeta(myRenamedFile)
        formData.append('uploadConfig', JSON.stringify(fileInfo))
        formData.append('upload', myRenamedFile)
        formData.append('metadata', JSON.stringify(metadata))
        formData.append('helpflag', 'true')
        if (formData) {
          var requestOptions = {
            method: 'POST',
            headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
            data: formData,
            url: `${tenant.apiUrl}/botmgmt-service/train/uploadContent`,
          }
        }

        return new Promise((resolve, reject) => {
          identityServices.identityApi.request(requestOptions).then(res => resolve(res.data))
        })
      }
    }
  }

  const UploadContent = (file, fileInfo) => {
    let { tenant } = props.tenant
    if (file) {
      let fileExt = file && file.name && file.name.substr(file.name.lastIndexOf('.') + 1)
      if (fileInfo && fileInfo.customInput && fileInfo.customInput.name) {
        let customFileName = editorFunc.serializeFileName(fileInfo.customInput.name)
        if (customFileName && fileExt) fileInfo.customInput.name = `${customFileName}.${fileExt}`
      }
      //this.setState({ errorMsg: false })
      const formData = new FormData()
      formData.append('uploadConfig', JSON.stringify(fileInfo))
      formData.append('upload', file)
      formData.append('helpflag', 'true')
     // fileInfo.type = 'video' ? null : formData.append('metadata', JSON.stringify({ height: '80px', width: '80px' })) 
      const requestOptions = {
        method: 'POST',
        headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
        data: formData,
        url: `${tenant.apiUrl}/botmgmt-service/train/uploadContent`,
      }
      let icon = editorFunc.getIcon(fileExt)
      return new Promise((resolve, reject) => {
        identityServices.identityApi.request(requestOptions).then(res => {
          let response = res.data
          if (fileInfo && fileInfo.type && fileInfo.type === 'attachment') {
            response.data.icon = icon
          }
          resolve(response)
        })
      })
    }
  }

  useEffect(() => {
    if (isEditDrawerOpen) {
      setTitle(data?.quickHelpContent?.properties?.title || '');
      setDescription(data?.quickHelpContent?.content || '');
      setComments(data?.quickHelpContent?.properties?.comments || '');
    }

  }, [isEditDrawerOpen]);


  const render_content = () => {
    let render = new marked.Renderer()
    render.list = function (body, ordered, start) {
      let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
      if (ordered) {
        return `<ol>${newtext}</ol>`
      } else {
        return `<ul>${newtext}</ul>`
      }
    }

    render.listitem = function (text) {
      return `<li>${text}</li>`;
    };

    render.blockquote = function (quote) {
      let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
      return `<blockquote>${newtext}</blockquote>`
    }
    render.code = function (string) {
      return string
    }
    render.image = function (href, title, text, alt, height, width) {
      var newImage = `<img src="${href}" alt="Image" title="Image">`
      return newImage
    }
    return render
  }

  const transformHTML = (input) => {
    // Create a document from the input string
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Function to recursively flatten list items
    function flattenList(element, depth = 1) {
      const items = [];
      Array.from(element.children).forEach(child => {
        if (child.tagName === "LI") {
          const newChild = document?.createElement("li");
          const textContent = Array.from(child.childNodes).filter(node => (node.nodeType === Node.TEXT_NODE)).map(node => (node.nodeValue?.trim())).join(''); // Get immediate text node content

          if (depth > 0) { // Skip class application for the first level
            newChild.className = `ql-indent-${Math.min(depth - 1, 8)}`;
          }
          newChild.textContent = textContent;
          // newChild.innerHTML = textContent;
          items.push(newChild.outerHTML);

          // Process nested lists
          if (child.children.length > 0) {
            Array.from(child.children).forEach(nested => {
              if (nested.tagName === "OL" || nested.tagName === "UL") {
                items.push(...flattenList(nested, depth + 1));
              }
            });
          }
        }
      });
      return items;
    }

    // Extract all the <ol> elements and transform them
    const listElements = doc.querySelectorAll('ol, ul');
    listElements.forEach(list => {
      const flattenedItems = flattenList(list);
      const newList = document.createElement(list.tagName); // Keep the original list type (ol or ul)
      newList.innerHTML = flattenedItems.join('');
      list.parentNode.replaceChild(newList, list);
    });

    return doc.body.innerHTML;
  }
  const processContent = (content) => {
    let contentStateeditor = marked(content, {
      renderer: render_content(),
    })
    contentStateeditor = replaceAll(contentStateeditor, '<p>[linebreak/]</p>', '<br/>')
    contentStateeditor = contentStateeditor.replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
    contentStateeditor = replaceAll(contentStateeditor, '[linebreak/]', '<br/>')
    contentStateeditor = replaceAll(contentStateeditor, '<p></p>)', '')
    contentStateeditor = transformHTML(contentStateeditor)

    let imagePixel = []
    let splitImgTag = contentStateeditor.split('"Image">')
    if (splitImgTag.length > 1) {
      splitImgTag.forEach(e => {
        let reg = e.split('}')[0].split('{')[1]
        if (reg) imagePixel.push(`style="${reg.replaceAll('=', ':').split(' ').join(';').concat(';')}"`)
      })
      if (imagePixel.length) {
        contentStateeditor = contentStateeditor
          .split('title="Image">')
          .map((x, idx) => {
            return x.concat(`"Image" ${imagePixel[idx]} >`)
          })
          .join('\n')
          .replace(/\{.*?\}/g, '')
          .replace('"Image" undefined >', '')
      }
    }
    contentStateeditor = replaceAll(contentStateeditor, '"Image" undefined >', '')


    const parser = new DOMParser();
    const doc = parser.parseFromString(contentStateeditor, 'text/html');

    // Parse the content and set all <a> tag colors to blue
    const anchors = doc.querySelectorAll('a');
    anchors.forEach(anchor => {
      if (anchor.style.color) {
        anchor.style.color = 'blue';
      } else {
        anchor.setAttribute('style', (anchor.getAttribute('style') || '') + 'color: blue;');
      }
    });

     // Find the video tags and add the desired attributes
      const videos = doc.querySelectorAll('video');
      videos.forEach(video => {
        video.setAttribute('controls', 'null');
        video.setAttribute('webkit-playsinline', 'true');
        video.setAttribute('playsinline', 'true');
        video.setAttribute('style', 'display:block');
      });

    contentStateeditor = doc.body.innerHTML;
    return contentStateeditor
  }
  async function fetchData() {
    let apiUrl = sessionStorage.getItem('apiUrl');
    let pathName = window.location.pathname.split('/');
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${apiUrl}/botmgmt-service/HelpContent`,
    };

    const requestOptionsSupportBot = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${apiUrl}/BotConfig/findWithParentAndCountAll?$filter=configType eq 'defaultSetting' and fullName eq 'supportBotUrl' and status eq 'Published'`,
    }

    if (pathName.length <= 2) {
      requestOptions.url += `?$filter=applocation eq '${pathName[1]}'`;
    } else if (pathName.length > 2) {
      requestOptions.url += `?$filter=appname eq '${pathName[1]}' and applocation eq '${pathName[2]}'`;
    }

    try {
      const res = await identityServices.identityApi.request(requestOptions);
      const resSupportBot = await identityServices.identityApi.request(requestOptionsSupportBot);
      if (resSupportBot.data && resSupportBot.data.length > 0) {
        const support = resSupportBot.data[0]
        setData(prev => ({
          ...prev,
          support: resSupportBot.data[0],
          iframe: `<iframe src="${support?.properties?.url}?isSupportBot[0]=${props.supportBotProps.tenantId}&isSupportBot[1]=${props.supportBotProps.email}" scrolling="no" width="100%" height="100%" style="" frameBorder="0" ></iframe>`
        }))
      }
      onOpen();
      if (res.data && res.data.length > 0) {
        res.data[0].content = editorFunc.convertQuillTags(res.data[0].content, true)
        const processedContent = processContent(res.data[0].content);
        res.data[0].content = processedContent
        setData(prev => ({
          ...prev,
          quickHelpContent: res.data[0],
        }));
      } else {
        setData(prev => ({
          ...prev,
          quickHelpContent: {
            content: `We’re here to provide you with the assistance and resources you need to effectively manage and maintain your employee support operations using [Rezolve.ai](https://www.rezolve.ai) bot application. \n\n </br> <div style="border-left: 4px solid blue; height: 18px; padding-left: 10px; border-radius: 3px; margin-left: 0px;"> <strong style="margin-left: 5px;">Frequently Asked Questions (FAQs):</strong> </div>  \nFor any frequently asked questions, we request you to refer to our [Help-Center](https://www.rezolve.ai/help-center) that we keep updated with frequent customer queries and updates about the product. You can access the Help Center at the following link – [Rezolve.ai|help-center](https://www.rezolve.ai/help-center) \n\n</br> If you have any other questions or need further assistance, please don’t hesitate to reach out to your dedicated customer service manager. We are committed to ensuring your success. </br></br> Sincerely, </br> Team [Rezolve.ai](https://www.rezolve.ai)`,
          }
        }));
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  const handleClick = () => {
    fetchData();
  };

  const convertVideoTags = (answer) => {
    return answer?.replace(/<video([^>]*)src="([^"]*)"([^>]*)>/g, (match, beforeSrc, src, afterSrc) => {
      // Update attributes and add poster
      const newAttributes = beforeSrc
        .replace(/\bcontrols\b|\bwidth\b|\bheight\b/g, '')
        .replace(/\bplaysinline\b/g, 'preload="none"');

      return `<video${newAttributes} src="${src}"${afterSrc}>${src} `;
    })
  }

  const makeAnswerFromArr = (content) => {
    let answer = '<ol>';
    const items = content.match(/<p>(.*?)<\/p>/g)?.map(item => item.replace(/<\/?p>/g, ''));
    items && items.forEach(item => {
      answer += '<li>' + item + '</li>';
    });

    answer += '</ol>';
    return answer;
  }




  const updateContent = (content) => {
    let answer = makeAnswerFromArr(content)

    return answer
  }

  const handleSave = async () => {
    setIsEditDrawerOpen(false);

    const apiUrl = sessionStorage.getItem('apiUrl');
    let pathName = window.location.pathname.split('/');
    let appname, applocation;

    if (pathName.length <= 2) {
      appname = pathName[1];
      applocation = pathName[1];
    } else if (pathName.length > 2) {
      appname = pathName[1];
      applocation = pathName[2];
    }

    let DescA = convertVideoTags(description);
    DescA.replace(new RegExp('</ul>\n<ol>', 'g'), '</ul>\n<p></p>\n<ol>')
    DescA.replace(new RegExp('</ol>\n<ul>', 'g'), '</ol>\n<p></p>\n<ul>')

    // Define request bodies for create and update operations
    const createRequestBody = {
      //tenantId: "63928f9c-a6d4-11e8-992e-1094bbe515da",
      appname: appname,
      applocation: applocation,
      content: DescA.replace(/<(\w+)[^>]*>\s*<\/\1>\n|(<p><\/p>\n)/g, '<br>'),
      properties: {
        title: title,
        comments: comments,
      },
      status: "Published"
    };

    const updateRequestBody = {
      id: data?.quickHelpContent?.id,
      content: DescA.replace(/<(\w+)[^>]*>\s*<\/\1>\n|(<p><\/p>\n)/g, '<br>'),
      properties: {
        title: title,
        comments: comments,
      },
      status: "Published"
    };

    const requestOptions = {
      method: data?.quickHelpContent?.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'clientApp': 'botapi',
        'realmName': props.tenant.tenantId
      },
      data: JSON.stringify(data?.quickHelpContent?.id ? updateRequestBody : createRequestBody),
    };

    try {
      let response;
      if (data?.quickHelpContent?.id) {
        updateRequestBody.content = updateContent(updateRequestBody.content);
        requestOptions.url = `${apiUrl}/botmgmt-service/HelpContent/update/${data.quickHelpContent.id}`;
        response = await identityServices.identityApi.request(requestOptions);
      } else {
        requestOptions.url = `${apiUrl}/botmgmt-service/HelpContent/upsert`;
        response = await identityServices.identityApi.request(requestOptions);
      }

      toast({
        title: 'Success',
        description: 'Help content saved successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      fetchData();
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };



  return (
    <>
      <Tooltip label="Help" aria-label="Help Tooltip">
        <Box>
          <BiHelpCircle
            boxSize={6}
            fontSize={'25px'}
            cursor="pointer"
            color="#94a3b8"
            _hover={{ textDecoration: 'underline' }}
            onClick={handleClick}
          />
        </Box>
      </Tooltip>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'lg'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ outline: 'none' }} />
          <DrawerBody className='help-center' pt='17px' >
            <Box display="flex" borderBottom={'1px solid #E2E8F0'} mb='12px' pb='16px'>
              <BiHelpCircle color="#2563EB" size={29} />
              <Box ms='10px'>
                <Text as='h6' fontWeight={600} fontSize={'20px'} color='#111827' >Help Center</Text>
                <Text color='#6B7280' fontSize={'16px'} fontWeight={400}>Find answers and get live support for your questions</Text>
              </Box>
            </Box>
            <Tabs height={45}>
              <CustomTabList mb='12px' justifyContent='space-between' >
                <Tab width={'150px'} >Quick Help</Tab>
                <Tab width={'150px'} >Support Bot</Tab>
                <Tab width={'150px'} > Request Support</Tab>
              </CustomTabList>
              <TabPanels >
                <TabPanel paddingLeft={0} paddingRight={0} padding={2}  >
                  <Box display="flex" pb='15px' mb='6px' alignItems="center" borderBottom='1px solid #E2E8F0'>
                    <Text fontSize={'16px'} fontWeight={600} >{data?.quickHelpContent?.properties?.title}</Text>
                    {data?.EditIcon ? <EditIcon
                      color="#94A3B8"
                      style={{ marginLeft: 'auto', cursor: 'pointer', width: '18.75px', height: '18.75px' }}
                      onClick={() => setIsEditDrawerOpen(true)}
                    /> : null}
                  </Box>
                  <ReactMarkdown children={data.quickHelpContent.content} rehypePlugins={[rehypeRaw]} />

                </TabPanel>
                <TabPanel paddingLeft={0} >
                  <div>AI Chat Bot</div>
                </TabPanel>
                <TabPanel paddingLeft={0} paddingRight={0} >
                  {
                    data.iframe && (<div style={{ "width": "100%", "height": "calc(100vh - 180px)", "position": "unset" }} dangerouslySetInnerHTML={{ __html: data.iframe }} />)
                  }
                </TabPanel>
              </TabPanels>
            </Tabs>


          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Edit Drawer */}
      <Drawer
        isOpen={isEditDrawerOpen}
        placement='right'
        onClose={() => setIsEditDrawerOpen(false)}
        size={'lg'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ outline: 'none' }} />
          <DrawerBody>
            <Box display="flex" alignItems="center" mb={2}>
              <IoArrowBackCircleOutline color='#2563EB' size={29} onClick={() => setIsEditDrawerOpen(false)} />
              &nbsp;<b style={{ fontSize: '20px', fontWeight: '600' }}> Edit Article </b>
            </Box>
            <Box>

              <p>Title</p>
              <Input
                fullWidth
                margin="normal"
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Box mt={2}>
                <p>Description</p>
                <QuillEditor
                  className="txt-editor"
                  textEditor={textEditor}
                  UploadImages={(file) => UploadImages(file)}
                  UploadContent={(file, fileInfo) => UploadContent(file, fileInfo)}
                  pixels={true}
                  // editorRef={setEditorReference}
                  toolbar={toolbar}
                  //defaultEditorState={elem.value}
                  value={description}
                  //onChange={this.onChange.bind(this, i)}
                  onChange={(value) => setDescription(value)}
                  spellCheck={true}
                  tenant ={props.tenant.tenant}
                />
                
              </Box>
              <br></br>
              <Box mt={2}>
                <p>Comments</p>
                <Textarea
                  fullWidth
                  margin="normal"
                  label="Comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Box>

            </Box>
          </DrawerBody>
          <DrawerFooter background='transparent'>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Stack direction='row' spacing={4} align='center'>
                <Button onClick={() => setIsEditDrawerOpen(false)} variant='outline' color='#A9A9A9' >Cancel</Button>
                <Button onClick={handleSave} background='#2563EB' color='#fff'>Save Help Content</Button>
              </Stack>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
