// import { NavLink as NavLink1 } from 'reactstrap'
// import { hostUrl } from '../../../config';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler, AppSwitch } from '@coreui/react'
import queryString from 'query-string'
import React, { Component } from 'react'
// import { publicPageUsers } from '../../../config';
// Locale
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// import dfLogo from '../../../assets/img/brand/df-logo.png'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { Wrap, WrapItem, Menu, MenuButton, Box, MenuList, MenuItem, Avatar, Switch, Text } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { BiHelpCircle } from 'react-icons/bi'
import {
	Button,
	DropdownItem,
	// Badge,
	// Dropdown,
	DropdownMenu,
	// NavbarToggler,
	// NavbarBrand,
	DropdownToggle,
	InputGroup,
	InputGroupButtonDropdown,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap'
import io from 'socket.io-client'
import { identityServices, profileReducers } from '..'
import { appName, appRootPath } from '../../../config'
import { routeList } from '../../../redux/routes'
import soundfile from '../sound/beep.mp3'
//Locale
import LangSelect from './LangSelect'
import DrawerHelper from './DrawerHelper'
import Notification from './Notification'
const LogoMark = 'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Light.png'
const logo = 'https://as-cdn.azureedge.net/cdn/Rezolve-Logo-Light.png'

let tenant = localStorage.getItem( 'tenant')
let v2Only = false
if (tenant && tenant !== "undefined" && typeof tenant === 'string'){
    const tenantData = JSON.parse(tenant || '{}')
    v2Only = tenantData?.custom?.v2Only || false
} 

class Header extends Component {
	constructor(props) {
		super(props)
		this.toggle = this.toggle.bind(this)
		this.isTeam = ''
		this.state = {
			dropdownOpen: false,

			IsTryOutRedirect: false,
			profile_pic: null,
			loadedWsRules: false,
			wsRoutes: {
				'manageQ&A': 'qna',
				smallTalk: 'qna',
				manageTask: 'qna',
				dashboard: 'qna',
				train: 'qna',
				liveChatScreen: 'livechat',
				liveChatSetting: 'livechat',
				defineClassification: 'qna',
				classificationHirerchy: 'qna',
				simpleTags: 'qna',
				LiveChatSupervisor: 'livechat',
			},
			splitButtonOpen: false,
			selectedWs: 'Default',
			currentProduct: {},
			subscribedProducts: [],
			isAppPreview: false,
			newAppRoute: false,
			isDrawerOpen: false,
			isNotoficationOpen: false
		}
	}

	toggleSplit = () => this.setState({ splitButtonOpen: !this.state.splitButtonOpen })

	componentWillMount() {
		const { identity, workspace } = this.props
		const { profile } = identity
		let currentUrl = window.location.href
		if (currentUrl && !currentUrl.includes('my-tickets')) {
			localStorage.removeItem('ActiveTab')
		}
		let urlParams = queryString.parse(this.props.location.search)

		// @ts-ignore
		if (urlParams) this.isTeam = urlParams.isTeams || sessionStorage.getItem('isTeams')

		if (profile) this.props.getUserById(profile.tenantId, profile.userId)
		if (appName && appName.toLowerCase() !== 'botmanagement') {
			this.getProducts()
		} else {
			this.getLatestWs()
		}
		if (window.location.pathname.includes('classification')) {
			this.getProducts()
		}
		
		let appRoute = routeList && routeList.find(r => r && r.path && r.path.indexOf(appRootPath) != -1)
		if (appRoute && appRoute.newAppRoute) {
			const { newAppRoute } = appRoute
			let newAppRoutePath = newAppRoute?.path || null
			if (newAppRoute.allowedRoles && Array.isArray(newAppRoute.allowedRoles) && newAppRoute.allowedRoles.length > 0) {
				const allowedRoles = newAppRoute?.allowedRoles
				if (allowedRoles.some(role => profile?.roles.indexOf(role) != -1)) {
					this.setState({ newAppRoute: newAppRoutePath })
				}
				else
					this.setState({ newAppRoute: false })
			}
			else
				this.setState({ newAppRoute: newAppRoutePath })
		}
		let teams = profile && profile.team
		workspace && teams && workspace.items && workspace.items.rules &&
			workspace.items.rules.sort((a, b) => {
				// let aTeam = a.props && a.props.team
				// let bTeam = b.props && b.props.team
				// let aIndex = teams.indexOf(aTeam)
				// let bIndex = teams.indexOf(bTeam)
				// return aIndex - bIndex
				if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) return -1;
				if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) return 1;
				return 0;
			})
	}

	getLatestWs() {
		const { ProfileEntity, workspace, userRoles, tenant } = this.props
		let ws
		let wsls = localStorage.getItem('workspace')
		let selectedWsData = JSON.parse(wsls)
		let tmIdx = selectedWsData && selectedWsData.qna && selectedWsData.qna.properties && selectedWsData.qna.properties.findIndex(obj => obj.userAttrKey === 'team')
		if (tmIdx >= 0 && selectedWsData && selectedWsData.qna) {
			ws = selectedWsData.qna.properties[tmIdx].values[0].value
		}

		if (localStorage.getItem('product') && ws && localStorage.getItem('product') !== ws && appName && appName.toLowerCase() == 'botmanagement') {
			let wsData =
				workspace &&
				workspace.items &&
				workspace.items.rules &&
				Array.isArray(workspace.items.rules) &&
				workspace.items.rules.length > 0 &&
				workspace.items.rules.filter(ws => {
					let cp = localStorage.getItem('product')
					if (cp === ws.props.team) {
						return ws
					}
				})
			wsData && this.handleWs(wsData && wsData[0], true)
		}
	}

	componentWillReceiveProps(props) {
		const { ProfileEntity, workspace, userRoles, tenant } = props
		let { loadedWsRules } = this.state
		let ws
		const user = ProfileEntity ? ProfileEntity.user : null
		if (user && user.attributes && user.attributes.profile_pic && user.attributes.profile_pic.length > 0) {
			this.setState({ profile_pic: user.attributes.profile_pic[0] })
		}

		let wsType = this.getWsTypeFromRouteName()

		// if (localStorage.getItem('product') && ws && localStorage.getItem('product') !== ws && appName && appName.toLowerCase() == 'botmanagement') {

		// 	//console.log(wsData, 'repeat')
		// }
		//console.log(workspace, localStorage.getItem('product'), 'repeating')
		// check the localstorage for workspaces
		if (workspace && !workspace.items && ProfileEntity && !ProfileEntity.loading && ProfileEntity.user && !loadedWsRules && appName && appName.toLowerCase() === 'botmanagement') {
			let attributes = (ProfileEntity.user && ProfileEntity.user.attributes) || []
			this.props.GetWSForUser({ wsType, attributes, apiUrl: tenant && tenant.apiUrl })
			this.setState({ loadedWsRules: true })
			// if(wsType) {
			//   let wsData = localStorage.getItem('workspace');
			//   wsData = (wsData && wsData != undefined && wsData != '') ? JSON.parse(wsData) : null
			//   if(wsData && wsData[wsType] && wsData[wsType].data && wsData[wsType].data.rulesApplied && wsData[wsType].data.rulesApplied.value) {
			//     this.setState({selectedWs: wsData[wsType].data.rulesApplied.value || 'Default', loadedWsRules: true})
			//   }
			//   this.props.GetWSForUser({ wsType, attributes })
			//   this.setState({loadedWsRules: true});
			//   // this.setState({selectedWs: wsData.rulesApplied.value || 'Default'})
			// } else {
			//   this.setState({loadedWsRules: true});
			// }
			// this.props.GetWSForUser({ wsType, attributes })
			// this.setState({loadedWsRules: true});
		}
		// if(workspace && !workspace.loading && workspace.items) {
		//   // set workspace for type in ls here
		//   this.setState({selectedWs: workspace.selectedWs[wsType]})
		// }
	}

	getTeams() {
		const { tenant, identity } = this.props
		const { profile } = identity
		let apiUrl = tenant.apiUrl
		const reqOps = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/tenant/teams/getTeams`,
		}
		return identityServices.identityApi.request(reqOps).then(response => {
			if (response && response.data && response.data) {
				const teams = response.data?.teamNames.filter(team => {
					return profile && profile.team && profile.team.includes(team.value)
				})
				return teams
			}
		})
	}

	async getProducts() {
		const { tenant, identity, t } = this.props
		const { profile } = identity
		let apiUrl = tenant.apiUrl

		let defaultTeam = localStorage.getItem('product') || (profile && profile.defaultTeam) || tenant.defaultTeam || (tenant.parentTenant && tenant.parentTenant.toLowerCase())
		// let subscribedProducts =
		// 	profile.teams &&
		// 	profle.teams.map(sub => {
		// 		return sub.serviceTeams && sub.serviceTeams[0]
		// 	})
		let subscribedProducts
		subscribedProducts = await this.getTeams()
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/productui/getproducts`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response && response.data) {
				let allDomains = response?.data?.map(product => product?.productname?.toLowerCase())
				localStorage.setItem('allDomains', allDomains.join(','))
				let productOptions = response?.data?.map(product => {
					return { label: t(product.producttitle), value: product.productname }
				})
				let products = []
				subscribedProducts &&
					subscribedProducts.filter(team => {
						if (!(productOptions && productOptions.some(product => product && product.value && product.value.toLowerCase() === team))) {
							products.push({ label: t(team?.label.replace(/^./, team?.label[0].toUpperCase())), value: team?.value.replace(/^./, team?.value[0].toUpperCase()) })
						}
					})
				defaultTeam = products?.filter(product => product.value.toLowerCase() === defaultTeam && defaultTeam.toLowerCase())
				if (subscribedProducts && subscribedProducts.length == 0) subscribedProducts = defaultTeam
				subscribedProducts = subscribedProducts?.sort((a, b) => {
					if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) return -1;
					if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) return 1;
					return 0;
				})
				this.setState(
					{
						currentProduct: defaultTeam && defaultTeam[0],
						subscribedProducts: subscribedProducts && subscribedProducts.filter(e => e.label),
					},
					() => {
						if (defaultTeam && defaultTeam[0] && defaultTeam[0].value) {
							localStorage.setItem('product', defaultTeam && defaultTeam[0] && defaultTeam[0].value.toLowerCase())
							localStorage.setItem('productSubcribed', JSON.stringify(subscribedProducts))
						}
					}
				)
			}
		})
	}

	getWsTypeFromRouteName() {
		const { wsRoutes } = this.state
		let { routeName } = this.props
		if (wsRoutes && wsRoutes[routeName]) {
			return wsRoutes[routeName]
		} else return null
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		})
	}
	sidebarToggle(e) {
		e.preventDefault()
		document.body.classList.toggle('sidebar-hidden')
	}
	sidebarMinimize(e) {
		e.preventDefault()
		document.body.classList.toggle('sidebar-minimized')
	}
	mobileSidebarToggle(e) {
		e.preventDefault()
		document.body.classList.toggle('sidebar-mobile-show')
	}
	asideToggle(e) {
		e.preventDefault()
		document.body.classList.toggle('aside-menu-hidden')
	}

	_browserNotification() {
		const { t } = this.props
		document
			.getElementById('myAudio')
			// @ts-ignore
			.play()
			.then(() => { })
			.catch(err => {
				if (navigator.userAgent.indexOf('Safari') !== -1) {
					toast.error(t('Notification sounds are disabled.'))
				} else {
					toast.error(t('Notification sounds are disabled.'))
				}
			})
		//document.querySelector('audio').play();
		if (!('Notification' in window)) {
			toast.error('Your browser does not support desktop notifications')
		}

		// Let's check whether notification permissions have already been granted
		else if (Notification.permission === 'granted') {
			// If it's okay let's create a notification
			var options = {
				body: 'Incoming Live chat request. Please review.',
				icon: process.env.PUBLIC_URL + '/ms-icon.png',
			}
			let title = 'Incoming Request'
			var notification = new Notification(title, options)
			setTimeout(notification.close.bind(notification), 7000)
		}

		// Otherwise, we need to ask the user for permission
		else if (Notification.permission !== 'denied') {
			Notification.requestPermission().then(function (permission) {
				// If the user accepts, let's create a notification
				if (permission === 'granted') {
					var options = {
						body: 'Incoming Live chat request. Please review.',
						icon: process.env.PUBLIC_URL + '/ms-icon.png',
					}
					let title = 'Incoming Request'
					var notification = new Notification(title, options)
					setTimeout(notification.close.bind(notification), 7000)
				}
			})
		}

		if (window.location.pathname !== '/agent') {
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
			// @ts-ignore
			link.type = 'image/png'
			// @ts-ignore
			link.rel = 'shortcut icon'
			// @ts-ignore
			link.href = process.env.PUBLIC_URL + '/ms-icon.png'
			document.getElementsByTagName('head')[0].appendChild(link)
		}
	}

	/**
	 * @todo pending queue implimentation
	 */
	initSocket(queueFlag) {
		let { tenant, namespace } = this.props
		let tenantId = tenant.id
		const that = this
		let apiUrl = tenant.apiUrl
		// socket.on('connect', function(){});
		let socketUrl = apiUrl && apiUrl.includes('://') ? 'https://' + apiUrl.split('/')[2] : 'https:// ' + apiUrl.split('/')[0]
		if (!this.socket) {
			if (queueFlag) {
				namespace.data.livechat.forEach(elem => {
					this.socket = io(tenantId ? socketUrl + elem : socketUrl, { path: '/api/v1/ws/socket.io' })
					this.socket.on('INCOMING_USER', function (data) {
						that._browserNotification()
					})
				})
			} else {
				this.socket = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
				this.socket.on('INCOMING_USER', function (data) {
					that._browserNotification()
				})
			} // queue flag condition ends
		} //socket check condition
	}

	setUrl(link) {
		if (link) window.open(link, '_blank')
	}

	handleWs(wsData, reLoad) {
		let wsType = this.getWsTypeFromRouteName()
		this.props.setSelectedWorkspace({ selectedWs: wsData, wsType, reLoad })
		// this.setState({selectedWs: wsData.rulesApplied.value || 'Default'})
	}

	handleSwitchproducts = selected => {
		if (window.location.pathname.includes('classification')) {
			this.handleWs(selected, false)
		}
		const { identity } = this.props
		identityServices.identityApi.interceptors.request.use(config => {
			config.headers.common['usecase'] = selected && selected.value.toLowerCase()
			return config
		})
		if (localStorage && localStorage['persist:root']) {
			localStorage.removeItem('persist:root')
		}
		let prevProduct = localStorage.getItem('product')
		this.setState(
			{
				currentProduct: selected,
			},
			() => {
				if (prevProduct === 'morsel' && selected.value.toLowerCase() !== 'morsel') {
					if (localStorage['persist:root']) {
						let newLocalStorage = JSON.parse(localStorage.getItem('persist:root'))
						if (newLocalStorage && newLocalStorage.globalSetting) delete newLocalStorage.globalSetting
						if (newLocalStorage && newLocalStorage.whiteLabelEntity) delete newLocalStorage.whiteLabelEntity
						localStorage.setItem('persist:root', JSON.stringify(newLocalStorage))
					}
					window.location.href = '/botmanagement/dashboard'
				} else {
					setTimeout(() => {
						if (localStorage['persist:root']) {
							let newLocalStorage = JSON.parse(localStorage.getItem('persist:root'))
							if (newLocalStorage && newLocalStorage.globalSetting) delete newLocalStorage.globalSetting
							if (newLocalStorage && newLocalStorage.whiteLabelEntity) delete newLocalStorage.whiteLabelEntity
							localStorage.setItem('persist:root', JSON.stringify(newLocalStorage))
						}
						window.location.reload()
					}, 1000)
				}
				localStorage.setItem('product', selected.value.toLowerCase())
				//this.props.getAllGlobalSettings(identity.profile.tenantUid)
			}
		)
	}

	handleAppPreview = () => {
		this.setState({ isAppPreview: !this.state.isAppPreview })
		const { tenant } = this.props
		const { newAppRoute } = this.state
		if (!newAppRoute) return
		let domain = tenant?.domain
		if (domain && domain.indexOf('https') == -1) domain = `https://${domain}`
		setTimeout(() => {
			window.location = `${domain}/${newAppRoute}`
		}, 1000)
	}

	handleDrawerOpen = () => {
		this.setState({ isDrawerOpen: true });
	};

	handleDrawerClose = () => {
		this.setState({ isDrawerOpen: false });
	};

	handleNotificationOpen = () => {
		this.setState({ isNotoficationOpen: true})
	}
	handleNotificationClose = () => {
		this.setState({ isNotoficationOpen: false})
	}

	render() {
		const { t, routeName } = this.props
		let { wsRoutes } = this.state
		let showSupport = false
		const {
			identity,
			setting,
			globalSetting,
			globalSetting: { uiFeature },
			namespace,
			whiteLabelEntity,
			headerHide,
			workspace,
			tenantConfig,
		} = this.props
		const { profile } = identity
		let liveChat = uiFeature && uiFeature.liveChat
		let ticketFeature = uiFeature && uiFeature.tickets
		if (
			liveChat &&
			liveChat.status &&
			!liveChat.agent_queue &&
			identity &&
			identity.roles &&
			identity.roles.length > 0 &&
			identity.roles.filter(element => ['agent', 'user_admin', 'chat_agent_supervisor', 'chat_agent_admin', 'chat_agent'].includes(element)).length > 0
		) {
			// this.initSocket(false)
		}

		if (
			liveChat &&
			liveChat.status &&
			liveChat.agent_queue &&
			identity &&
			identity.roles &&
			identity.roles.length > 0 &&
			identity.roles.filter(element => ['agent', 'user_admin', 'chat_agent_supervisor', 'chat_agent_admin', 'chat_agent'].includes(element)).length > 0 &&
			namespace &&
			!namespace.loading &&
			namespace.data &&
			namespace.data.livechat &&
			namespace.data.livechat.length > 0
		) {
			// this.initSocket(true)
		}
		let support = uiFeature && uiFeature.support
		let supportButton = identity && identity.roles && identity.roles.find(role => role === 'support_user' || role === 'support_supervisor')
		if (supportButton == 'support_user' || supportButton == 'support_supervisor') {
			if (support && support.status) {
				let { apps } = support
				showSupport = apps.includes(appRootPath)
			}
		}

		// const isTryOutHide = setting && setting.setting && setting.setting.setting.isTryOutHide;
		let { profile_pic, loadedWsRules, splitButtonOpen, selectedWs } = this.state
		let profilePicUrl = null
		if (profile_pic) {
			profilePicUrl = profile_pic
		}
		let profilePic
		if (profilePicUrl) {
			profilePic = <img src={profilePicUrl} className="img-avatar" alt="profile pic" />
		} else {
			profilePic = <img src="/assets/img/avatars/u-upload.png" className="img-avatar" alt="profile pic" />
		}
		let companyLogo = logo
		let theme = whiteLabelEntity && whiteLabelEntity.itemData ? whiteLabelEntity.itemData : null
		if (theme && theme.pop) {
			let data = theme.find(c => c.publicPrivate === 'private')
			if (data) {
				companyLogo = data.config && data.config.companyLogo ? data.config.companyLogo : logo
			}
		}
		
		// if(tenantConfig && !tenantConfig.loading && tenantConfig.data && tenantConfig.data.length > 0) {
		//   let wsTcData = tenantConfig.data.find(o => o.name == 'workspaceFilters')
		// }

		let workspaceDropdownVisible = true
		if (uiFeature && uiFeature.workspaceFilters && uiFeature.workspaceFilters.enabled && uiFeature.workspaceFilters.enabled == 'on') {
			workspaceDropdownVisible = true
		}
		let wsType = this.getWsTypeFromRouteName()
		let wsRulesOnWsType =
			workspace &&
			workspace.items &&
			workspace.items.rules &&
			Array.isArray(workspace.items.rules) &&
			workspace.items.rules.length > 0 &&
			workspace.items.rules.filter(ws => ws.workspaceType === wsType)

		return (
			<React.Fragment>
				<ToastContainer autoClose={4000} />
				{/* {(!headerHide || !headerHide.toggle) && <AppSidebarToggler className="d-lg-none" display="md" mobile />} */}
				
				<div className={`header-logo${this.props.isSidebarOpen?' collapse':''}`}>
				{(!headerHide || !headerHide.logo) && !this.props.isSidebarOpen && (
					<AppNavbarBrand full={{ src: companyLogo, width: 150, height: 40, alt: 'Rezolve.ai' }} 
						minimized={{ src: companyLogo, width: 28, height: 28, alt: 'Rezolve.ai' }} 
					/>
				)}
				{(!headerHide || !headerHide.logo) && this.props.isSidebarOpen && (
					<AppNavbarBrand full={{ src: LogoMark, width: 50, height: 40, alt: 'Rezolve.ai' }} 
						minimized={{ src: LogoMark, width: 15, height: 15, alt: 'Rezolve.ai' }} 
						className="icon-collapsed"
					/>
				)}
				</div>

				{/* {(!headerHide || !headerHide.toggle) && <AppSidebarToggler className="d-md-down-none" display="sm" />} */}

				<audio src={soundfile} id="myAudio">
					<source src={soundfile} type="audio/mpeg" />
				</audio>
				<Nav className="ml-auto" navbar style={{ display: 'flex', alignItems: 'center' }} >
					{/* {this.state.newAppRoute && ticketFeature?.newUi?.toggleUi === 'true' && (
						<NavItem className="d-md-down-none">
							{t('Switch to preview')}
							<AppSwitch
								className={'float-right mb-0 ml-1'}
								label
								color={'info'}
								checked={this.state.isAppPreview}
								size={'sm'}
								variant={'pill'}
								onChange={this.handleAppPreview.bind(this)}
								disabled={!!this.state.isAppPreview}></AppSwitch>
						</NavItem>
					)} */}

					{/* <NavItem className="d-md-down-none try-out-bot">
            {!isTryOutHide && !this.state.IsTryOutRedirect &&  <NavLink1 target="_blank" href={'../../../virtualagent/tryout'} className={"nav-link"} >Try it Out</NavLink1>}
            {!isTryOutHide && this.state.IsTryOutRedirect && <button onClick={this.handleRedirection} className="to-btn bt-tryout">Try it Out</button>}
          </NavItem> */}
					{/* {(!headerHide || !headerHide.profile) && <LangSelect {...this.props} />} */}
					{/* <DrawerHelper
						isOpen={this.state.isDrawerOpen}
						onOpen={this.handleDrawerOpen}
						onClose={this.handleDrawerClose}
						supportBotProps={{ tenantId: profile?.tenantId, email: profile?.email }}
						profile={profile}
					/> &nbsp; */}

					{/* {showSupport && (!headerHide || !headerHide.support) && window.location.pathname.split('/')[1] !== 'approvals' && (
						<NavItem className="d-md-down-none">
							<NavLink href="#" className="support-btn" onClick={this.props.support}>
								<i className="fa fa-question-circle"></i>
								{t('Support')}
							</NavLink>
						</NavItem>
					)} */}

					{/* {this.state.subscribedProducts &&
						this.state.subscribedProducts.length > 1 &&
						this.state.currentProduct &&
						appName &&
						appName.toLowerCase() !== 'botmanagement' &&
						appName.toLowerCase() !== 'mainapp' &&
						window.location.pathname.split('/')[2] !== 'my-chats' &&
						window.location.pathname.split('/')[2] !== 'unavailabilityReason' &&
						window.location.pathname.split('/')[2] !== 'my-tickets' &&
						window.location.pathname.split('/')[2] !== 'EmailTemplates' &&
						!window.location.pathname.includes('/dashboard') &&
						!window.location.pathname.toLowerCase().includes('/processflow/overriddenstates') &&
						!window.location.pathname.toLowerCase().includes('/statusconfiguration/') &&
						!window.location.pathname.toLowerCase().includes('/livechatkpi') &&
						!window.location.pathname.toLowerCase().includes('/realtimeinsights') &&
						window.location.pathname !== '/tickets/' &&
						!window.location.pathname.includes('/tickets/ticket-detail') && (
							<li className="d-md-down-none nav-item" style={{ marginLeft: '10px' }}>
								<InputGroup>
									<InputGroupButtonDropdown addonType="prepend" isOpen={splitButtonOpen} toggle={this.toggleSplit}>
										<Button outline>{this.state.currentProduct.label}</Button>
										<DropdownToggle split outline />
										<DropdownMenu>
											{this.state.subscribedProducts.map((item, idx) => {
												return (
													<DropdownItem key={idx} onClick={this.handleSwitchproducts.bind(this, item)}>
														{item.label}
													</DropdownItem>
												)
											})}
											<DropdownItem divider />
										</DropdownMenu>
									</InputGroupButtonDropdown>
								</InputGroup>
							</li>
						)} */}


					<Wrap pb='2' pt={1}>

						{this.state.newAppRoute && ticketFeature?.newUi?.toggleUi === 'true' && (
						<WrapItem gap='2' p={'2px'} pt={2} className='theme-color'>
							<Text 	fontFamily={'Inter'}
							fontSize={'13px'}
							pt={'4px'}
							color={'#111827'}>{t('Switch to Current Version')}</Text>
							{/* <Switch
								id='switchClassic'
								ml={2}
								size="sm"
								isChecked={this.state.isAppPreview}
								value={!this.state.isAppPreview}
								disabled={!!this.state.isAppPreview}
								onChange={this.handleAppPreview.bind(this)}
							/> */}
														<AppSwitch
								className={'float-right mb-0 ml-1'}
								label
								color={'info'}
								checked={this.state.isAppPreview}
								size={'sm'}
								variant={'pill'}
								onChange={this.handleAppPreview.bind(this)}
								disabled={!!this.state.isAppPreview}></AppSwitch>
						</WrapItem>
						)}
						{/* <Nav className="ml-auto" navbar style={{ display: 'flex', alignItems: 'center' }} > */}
						{(!v2Only) ? <Notification
						isOpen={this.state.isNotoficationOpen}
						onOpen={this.handleNotificationOpen}
						onClose={this.handleNotificationClose}
						profile={profile}
					    /> : null}
						{/* </Nav> */}
						{/* <Notification
						isOpen={this.state.isNotoficationOpen}
						onOpen={this.handleNotificationOpen}
						onClose={this.handleNotificationClose}
						profile={profile}
					    /> */}

						{(!headerHide || !headerHide.profile) && (<WrapItem p={'2px'} className='theme-color'>
							<LangSelect {...this.props} />
						</WrapItem>
						)}



						{/* {showSupport && (!headerHide || !headerHide.support) && window.location.pathname.split('/')[1] !== 'approvals' && (
							<WrapItem p={'2px'} className='theme-color'>
								<NavItem className="d-md-down-none">
									<NavLink href="#" className="support-btn" onClick={this.props.support}>
										<i className="fa fa-support" style={{ fontSize: '12px!important' }}></i>
										{t('Support')}
									</NavLink>
								</NavItem>

							</WrapItem>
						)} */}

<WrapItem p={'2px'} className='theme-color'>
							<DrawerHelper
								isOpen={this.state.isDrawerOpen}
								onOpen={this.handleDrawerOpen}
								onClose={this.handleDrawerClose}
								supportBotProps={{ tenantId: profile?.tenantId, email: profile?.email }}
								profile={profile}
								tenant={this.props}
							/>
						</WrapItem>
						
						{this.state.subscribedProducts &&
							this.state.subscribedProducts.length > 1 &&
							this.state.currentProduct &&
							appName &&
							appName.toLowerCase() !== 'botmanagement' &&
							appName.toLowerCase() !== 'mainapp' &&
							window.location.pathname.split('/')[2] !== 'my-chats' &&
							window.location.pathname.split('/')[2] !== 'unavailabilityReason' &&
							window.location.pathname.split('/')[2] !== 'my-tickets' &&
							window.location.pathname.split('/')[2] !== 'EmailTemplates' &&
							!window.location.pathname.includes('/dashboard') &&
							!window.location.pathname.toLowerCase().includes('/processflow/overriddenstates') &&
							!window.location.pathname.toLowerCase().includes('/statusconfiguration/') &&
							!window.location.pathname.toLowerCase().includes('/livechatkpi') &&
							!window.location.pathname.toLowerCase().includes('/realtimeinsights') &&
							window.location.pathname !== '/tickets/' &&
							!window.location.pathname.includes('/tickets/ticket-detail') && (<WrapItem p={1} className='theme-color'>


								<li className="d-md-down-none nav-item" style={{ marginLeft: '10px' }}>
									<InputGroup>
										<InputGroupButtonDropdown style={{height:'25px'}}  addonType="prepend" isOpen={splitButtonOpen} toggle={this.toggleSplit}>
											<Button style={{padding:'2px 5px'}} outline>{this.state.currentProduct.label}</Button>
											<DropdownToggle split outline />
											<DropdownMenu>
												{this.state.subscribedProducts.map((item, idx) => {
													return (
														<DropdownItem key={idx} onClick={this.handleSwitchproducts.bind(this, item)}>
															{item.label}
														</DropdownItem>
													)
												})}
												<DropdownItem divider />
											</DropdownMenu>
										</InputGroupButtonDropdown>
									</InputGroup>
								</li>


							</WrapItem>
							)}

						{workspace &&
							!window.location.pathname.includes('/dashboard') &&
							!window.location.pathname.includes('/train') &&
							!window.location.pathname.includes('simple-tags') &&
							!workspace.loading &&
							workspace.items &&
							workspace.items.rules &&
							Array.isArray(workspace.items.rules) &&
							workspace.items.rules.length > 0 &&
							wsRoutes[routeName] &&
							wsRulesOnWsType &&
							wsRulesOnWsType.length > 0 &&
							!(window.location.pathname.includes('classification') && this.state.subscribedProducts.length <= 1) &&
							workspaceDropdownVisible && (<WrapItem p={1} className='theme-color'>


								<li className="d-md-down-none nav-item" style={{ marginLeft: '10px' }}>
									<InputGroup>
										<InputGroupButtonDropdown style={{height:'25px'}} addonType="prepend" isOpen={splitButtonOpen} toggle={this.toggleSplit}>
											{window.location.pathname.includes('classification') &&
												this.state.subscribedProducts &&
												this.state.subscribedProducts.length > 1 &&
												this.state.currentProduct ? (
												<Button  style={{padding:'2px 5px'}} outline>{this.state.currentProduct.label}</Button>
											) : (
												<Button style={{padding:'2px 5px'}} outline>
													{(workspace.selectedWsMeta && workspace.selectedWsMeta[wsRoutes[routeName]] && workspace.selectedWsMeta[wsRoutes[routeName]]['label']) || 'Default'}
												</Button>
											)}
											<DropdownToggle split outline />
											{window.location.pathname.includes('classification') &&
												this.state.subscribedProducts &&
												this.state.subscribedProducts.length > 1 &&
												this.state.currentProduct ? (
												<DropdownMenu>
													{this.state.subscribedProducts.map((item, idx) => {
														return (
															<DropdownItem key={idx} onClick={this.handleSwitchproducts.bind(this, item)}>
																{item.label}
															</DropdownItem>
														)
													})}
													<DropdownItem divider />
												</DropdownMenu>
											) : (
												<DropdownMenu>
													{workspace &&
														workspace.items &&
														workspace.items.rules &&
														Array.isArray(workspace.items.rules) &&
														workspace.items.rules.length > 0 &&
														wsRoutes &&
														workspace.items.rules.map((item, idx) => {
															if (item.workspaceType == wsRoutes[routeName]) {
																return (
																	<DropdownItem key={idx} onClick={this.handleWs.bind(this, item, false)}>
																		{item.label}
																	</DropdownItem>
																)
															} else return <></>
														})}
													{workspace &&
														workspace.items &&
														workspace.items.rules &&
														Array.isArray(workspace.items.rules) &&
														workspace.items.rules.length > 0 &&
														!workspace.items.rules.find(w => w.label.toLowerCase() === 'global') && (
															<DropdownItem onClick={this.handleWs.bind(this, { name: 'Unassigned', label: 'Unassigned' }, false)}>{'Unassigned'}</DropdownItem>
														)}
													<DropdownItem divider />
												</DropdownMenu>
											)}
										</InputGroupButtonDropdown>
									</InputGroup>
								</li>

							</WrapItem>
							)}

						{(!headerHide || !headerHide.profile) && (<WrapItem p={1} className='theme-color chakra-menu'>
							<Menu>
								<MenuButton
									transition='all 0.2s'
									className={'chakra-header-btn'}
								>
									<Box
										mr={2}
										display="flex"
										justifyContent={'space-between'}
										fontSize="12px"
										color={'#6c7280'}
										className={'chakra-header-box'}
									>
										<Box>
											<Avatar 
											className='header-avatar'
											fontWeight='400' name={`${profile?.name || profile?.preferred_username}`} h="24px" w="24px" />
										</Box>
										<Box className='header-title' pl={1} pt={1}>{profile?.name || profile?.preferred_username}</Box>
										<Box className='header-down-icon' pt={'3px'}>    <ChevronDownIcon boxSize={5} /></Box>

									</Box>
								</MenuButton>
								<MenuList fontSize="14px">
									<MenuItem>
										<div className="" style={{ padding: '0', width: '100%' }}>
											<Link to="/profile" style={{display:'inline-block', width:'100%', padding: '2px', color: '#9c9c9c' }}>
												{t('Profile')}
											</Link>
										</div>
									</MenuItem>
									<MenuItem onClick={this.props.logout.bind(this, t)}>
										<div className="lnk-btn-h" style={{ padding: '0', width: '100%' }}>
											<button className="logout-profile btn btn-link pull-right"
												style={{ padding: '0', textDecoration: 'none' }}
												>
												{t('Logout')}
											</button>
										</div>
									</MenuItem>
								</MenuList>
							</Menu>
						</WrapItem>)}
					</Wrap>

					{/* {(!headerHide || !headerHide.profile) && (
						<AppHeaderDropdown direction="down">
							<DropdownToggle nav>{profilePic}</DropdownToggle>
							<DropdownMenu right style={{ right: 'auto' }}>
								<DropdownItem header tag="div" className="text-center">
									<strong>{profile && profile.name ? profile.name : profile ? profile.email : 'Guest'}</strong>
								</DropdownItem>
								<div className="lnk-btn-h">
									<Link to="/profile" style={{ padding: '0' }}>
										<i className="fa fa-user"></i> {t('Profile')}
									</Link>
								</div>
								<div className="lnk-btn-h">
									<button className="logout-profile btn btn-link pull-right" onClick={this.props.logout.bind(this, t)}>
										<i className="fa fa-lock"></i>
										{t('Logout')}
									</button>
								</div>
							</DropdownMenu>
						</AppHeaderDropdown>
					)} */}
				</Nav>
				{headerHide && headerHide.profile && !this.isTeam && (
					<span onClick={this.props.logout.bind(this, t)} title={t ? t(`Logout`) : 'Logout'}>
						<i className="fa fa-power-off fa-6" aria-hidden="true" style={{ fontSize: '35px', marginLeft: '10px', cursor: 'pointer' }}></i>
					</span>
				)}
			</React.Fragment>
		)
	}
}
export default withTranslation()(Header)
